//src/utils/request.js
import axios from 'axios'
import router from "../router";
import { Loading, Message,MessageBox } from 'element-ui';
let loadingAll;
let loading_count = 0; //请求计数器




const toLogin = () => {
    router.replace({
        path: '/'
    });
}

function startLoading() {
    if (loading_count == 0) {
        loadingAll = Loading.service({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    }
    //请求计数器
    loading_count++;
}

function endLoading() {
    loading_count--;//只要进入这个函数，计数器就自减，直到。。
    if (loading_count <= 0) {
        loadingAll.close();
    }
}


// 创建 axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
    timeout: 10000, // request timeout
})



// 添加请求拦截器
service.interceptors.request.use(
    config => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
        // 请求拦截进来调用显示loading效果

        config.headers['Content-Type'] = 'application/json;charset=UTF-8;'
        config.headers['Accept'] = 'application/json'
        config.headers['Request-From'] = 'mobile'
        let token = localStorage.getItem('Authorization') || null
        if (token) {
            // 如果token不为null，否则传token给后台
            config.headers['token'] = token
        }
        //startLoading()
        return config
    }, error => {
        // 对请求错误做些什么
        return Promise.reject(error)
    })


// http response 响应拦截器
service.interceptors.response.use(
    //请求成功
    response => {
        // 响应拦截进来隐藏loading效果，此处采用延时处理是合并loading请求效果，避免多次请求loading关闭又开启

        //console.log(response.data, '77')
        if (response.data.code == 401) {
            // token过期或无koken,则跳转登录页
            location.href = '/'
            //location.href = '/stuLogin'
            // MessageBox({
            //     message: '登录过期，请重新登录',
            //     callback: action => {
            //         toLogin();
            //     }
            // })
            // MessageBox.confirm("登录过期，请重新登录?",'提示').then(action => {
            //     location.href = '/'
            // });
            // MessageBox.confirm('登录过期，请重新登录?', '提示', {
            //     confirmButtonText: '确定',
            //     type: 'warning',
            // }).then(action => {
            //      location.href = '/'
            // });


        } else if (response.status == 404) {
            Message({
                message: "抱歉，没找到资源",
                type: "error"
            });

        }
        //endLoading()
        // ...对状态码进行拦截判断
        return response.data
    },
    //请求失败
    error => {
        // 响应拦截进来隐藏loading效果，此处采用延时处理是合并loading请求效果，避免多次请求loading关闭又开启

        // 当找不到资源的时候
        if(error.response.data.status==404) {
            //ElMessage.error(error.response.data.message)
            Message({
                message: "抱歉，没找到资源",
                type: "error"
            });
        }
        endLoading()
        console.log(error.response.data.status,'7888')
        return Promise.reject(error.response.data);

    })


export default service
